.form-container{
    text-align: center;
    border-radius: 5px;
    height: 500px;
    display: flex;
}
.form{
  display: flex;
  justify-content: center;
}
.form-inner {
  padding: 10px;
}
.logo-image{
  width: 200px;
  height: auto;
  margin-left: 20px;
}
.main-div{
  background-color: #4e73df;  
  background-image: linear-gradient(180deg, #ffffff 10%, #ffffff 100%);
}
.for-heading{
  color: yellow;
}
.reset-sunmit-button{
 display: flex;
 justify-content: space-between;
 margin: 7px 0px 7px 0px;
}
.draganddropimage{
  height: auto;
  width: 370px;
  margin-top: 10px;
}
.submitbutton{
  background-color: yellow;
}
.resetall{
  background-color: yellow;
}
.script{
  text-align: center;
  background-color: yellow;
  border-radius: 5px ;
}
.editbutton{
  border: none;
  background-color: ghostwhite;
}
.editbutton:hover{
  background-color: ghostwhite;
}