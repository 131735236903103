.a1
{
    width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100";
}
.a2
{
    width: 20%" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100";
}
.a3
{
    width: 40%" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100";
}
.a4
{
    width: 80%" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100";
}
.a5
{
    width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100";
}
.a6
{
    width: 25rem;
}
.a7
{
    width: 60%" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100";
}
.toggled {
    overflow: visible;
    width: 6.5rem!important;
}
.toggled1 {
    width: 0!important;
    overflow: hidden;
}
.logo-img{
   /* margin-left: 27px; */
}
.logo-upper{
 
    display: flex;
   align-items: center;
}
.admin-text{
    color: white;
    display: flex;
    justify-content: center;
    color:#feed00
    
}
.menu-icon{
    display: flex;
    align-items: center;
    padding: 10px;
}

.dropbtn {
    background-color: #04AA6D;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
    background-color: white;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {background-color: #ddd;}
  
  .dropdown:hover .dropdown-content {display: block;}
  
  .dropdown:hover .dropbtn {background-color: #3e8e41;}