.loader {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: white;
    z-index: 9999;
  }
  .spinner {
    width: 70px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -9px 0 0 -35px;
  }
  
  .spinner > div {
    width: 18px;
    height: 18px;
    background-color: #0a3047;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }
  
  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  
  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  } 
  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
  
    40% {
      -webkit-transform: scale(1);
    }
  }
  
  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
  
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }